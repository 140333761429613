<template>
  <div v-if="$store.state.fastPermissoes.ativo == 'S' && ($store.state.fastPermissoes.administrador == 'S')">
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav v-if="fastSecretariaAvaliacao.id_secretaria_avaliacao > 0 && fastSecretariaAvaliacao.id_secretaria_avaliacao_uc > 0 && fastSecretariaAvaliacao.id_avaliacao_modelo">
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>

            <li class="fd-app-breadcrumb-item">
              <a class="aluno_font_color" href="#" @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')">Modo secretaria</a>
            </li> 
            <li class="fd-app-breadcrumb-item">
              <a class="aluno_font_color" href="#" @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/cursos')">Cursos</a>
            </li>

            <li class="fd-app-breadcrumb-item">
              <a class="aluno_font_color" href="#" @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/curso/' + parseInt(getUrlParameter('id_secretaria_curso')))">Curso</a>
            </li>

            <li class="fd-app-breadcrumb-item">
              <a class="aluno_font_color" href="#" @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/curso/' + parseInt(getUrlParameter('id_secretaria_curso')) + '/programa/' + parseInt(getUrlParameter('id_secretaria_curso_programa')))">Módulo</a>
            </li>

            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Provas</a>
            </li>

          </ul>
        </nav>
        <nav v-else>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>

            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>

            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Provas</a>
            </li>

          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Gerenciamento de
                  <span id="txtDashboardNomePlataforma">Provas</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <section class="fd-app-content">
                <div class="text-center mt-3">
                  <a
                    class="btn btn-primary ml-2 mt-2"
                    href="#"
                    @click.prevent="showModal('modalCriarProva')"
                  >+ Criar prova</a>
                  <modal
                    name="modalCriarProva"
                    :scrollable="true"
                    height="auto"
                    :shift-y="0.1"
                    :focus-trap="true"
                    :adaptive="true"
                  >
                    <div class="row p-4">
                      <div class="col-6 pl-4 pr-4 text-left">
                        <h4>Criar prova</h4>
                      </div>
                      <div class="col-6 pl-4 pr-4 text-right">
                        <a
                          class="btn btn-secondary"
                          href="#"
                          @click.prevent="hideModal('modalCriarProva')"
                        >
                          <i
                            class="fa fa-times"
                            aria-hidden="true"
                          />
                        </a>
                      </div>
                      <div class="col-12 p-4">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <input
                                v-model="novaProva.nome_prova"
                                class="form-control"
                                type="text"
                                placeholder="Nome da prova"
                              >
                            </div>
                            <div class="form-group">
                              <select
                                v-model="novaProva.tipo_prova"
                                class="form-control"
                                type="text"
                              >
                                <option value="O">
                                  Questões objetivas
                                </option>
                                <option value="D">
                                  Questões dissertativas
                                </option>
                              </select>
                            </div>
                            <div class="form-group mb-0 text-left">
                              <button
                                href="#"
                                type="button"
                                class="btn btn-primary"
                                @click.prevent="criaFastProva"
                              >
                                Criar prova
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </modal>
                  <button
                    v-if="fastAtividade.id_atividade"
                    class="btn btn-danger ml-2 mt-2"
                    @click="voltarParaAtividade(fastAtividade.id_atividade)"
                  >
                    <b-icon-arrow-counterclockwise /> Voltar para atividade
                  </button>
                  <button
                    v-if="fastSecretariaAvaliacao.id_secretaria_avaliacao > 0 && fastSecretariaAvaliacao.id_secretaria_avaliacao_uc > 0 && fastSecretariaAvaliacao.id_avaliacao_modelo"
                    class="btn btn-danger ml-2 mt-2"
                    @click="voltarParaSecretariaAvaliacao()"
                    
                  >
                    <b-icon-arrow-counterclockwise /> Voltar para avaliação
                  </button>
                </div>
                <div class="fd-plataforma-gestao" />
              </section>
              <div class="row">
                <div class="col-12">
                  <h3 class="aluno_font_color">
                    Base de provas ({{ provasFiltro.length }})
                  </h3>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-4 pl-0">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Filtrar pelo nome da prova"
                    @keyup="filtraProva"
                  >
                </div>
              </div>

              <!-- /fd-app-welcome -->
              <div
                v-if="provasFiltro.length"
                class="row"
              >
                <div
                  v-for="prova in pageOfProvas"
                  :key="prova.id_prova"
                  class="col-12 mb-4 shadow p-3 mb-5 bg-white rounded"
                >
                  <h4>{{ prova.nome_prova }} #{{ prova.id_prova }}</h4>
                  <tabs
                    :options="{ useUrlFragment: false }"
                    @clicked="tabProvasClicked"
                  >
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Detalhes da prova"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <h6 class="aluno_font_color">
                                Nome da prova
                              </h6>
                              <input
                                v-model="prova.nome_prova"
                                type="text"
                                class="form-control"
                                @keyup.prevent="prova.alteracao_pendente = true"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                              <h6 class="aluno_font_color">
                                Tipo de prova
                              </h6>
                              <input
                                v-if="prova.tipo_prova == 'D'"
                                type="text"
                                class="form-control"
                                value="Dissertativa"
                                readonly
                              >
                              <input
                                v-else
                                type="text"
                                class="form-control"
                                value="Objetiva"
                                readonly
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-2 text-center">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                              <h6>Excluir prova</h6>
                              <button
                                class="btn btn-danger btn-block ml-2"
                                @click.prevent="exibeModalExcluirProva(prova.id_prova)"
                              >
                                <small> <i
                                  class="fa fa-ban"
                                  aria-hidden="true"
                                /> &nbsp; Excluir </small>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <tab
                      prefix="<i class='fa fa-file-pdf' aria-hidden='true'></i>&nbsp"
                      name="Questões"
                      :suffix="'<span class=&quot;suffix&quot;>' + prova.n_questoes + '</span>'"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6 text-left">
                          <h4>Questões</h4>
                        </div>
                        <!-- Prova objetiva -->
                        <div
                          v-if="prova.tipo_prova != 'D'"
                          class="col-sm-12 col-md-12 col-lg-6 text-right"
                        >
                          <button
                            class="btn btn-primary btn-sm mt-2 ml-2 mb-2"
                            @click.prevent="exibeModalCriarQuestao(prova.id_prova)"
                          >
                            <small>+ &nbsp; Criar nova questão</small>
                          </button>
                          <button
                            class="btn btn-primary btn-sm mt-2 ml-2 mb-2"
                            @click.prevent="exibeModalBuscarQuestoesConcurseiro(prova.id_prova)"
                          >
                            <small>+ &nbsp; Buscar questões no concurseiro</small>
                          </button>
                        </div>
                        <!-- Prova dissertativa -->
                        <div
                          v-else
                          class="col-sm-12 col-md-12 col-lg-6 text-right"
                        >
                          <button
                            class="btn btn-primary btn-sm mt-2 ml-2 mb-2"
                            @click.prevent="exibeModalCriarQuestaoDissertativa(prova.id_prova)"
                          >
                            <small>+ &nbsp; Criar nova questão</small>
                          </button>
                        </div>

                        <div
                          v-if="fastAtividade.id_atividade && prova.tipo_prova != 'D'"
                          class="col-sm-12 col-md-12 col-lg-4 text-center"
                        >
                          <div class="row">
                            <div
                              v-if="fastAtividade.tipo_atividade == 'quiz'"
                              class="col-sm-12 col-md-12 col-lg-12 text-left mb-2"
                            >
                              <div v-if="verificaQuestoesQuizNaProva(prova.questoes)">
                                <button
                                  class="btn btn-sm btn-success"
                                  @click.prevent="adicionarQuestaoAtividade(0, prova.id_prova, fastAtividade.tipo_atividade)"
                                >
                                  <small>+ &nbsp; Adicionar todas as questões no QUIZ</small>
                                </button>
                              </div>
                              <div v-else>
                                <h6 class="text-success" />
                              </div>
                            </div>
                            <div
                              v-if="fastAtividade.tipo_atividade == 'avaliacao'"
                              class="col-sm-12 col-md-12 col-lg-12 text-left mb-2"
                            >
                              <div v-if="verificaQuestoesQuizNaProva(prova.questoes)">
                                <button
                                  class="btn btn-sm btn-success"
                                  @click.prevent="adicionarQuestaoAtividade(0, prova.id_prova, fastAtividade.tipo_atividade)"
                                >
                                  <small>+ &nbsp; Adicionar todas as questões na avaliação</small>
                                </button>
                              </div>
                              <div v-else>
                                <h6 class="text-success" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else-if="fastSecretariaAvaliacao.id_secretaria_avaliacao > 0" class="col-sm-12 col-md-12 col-lg-4 text-center">
                          <div
                              class="col-sm-12 col-md-12 col-lg-12 text-left mb-2"
                            >
                              <div v-if="verificaQuestoesSecretariaAvaliacaoNaProva(prova.questoes)">
                                <button
                                  class="btn btn-sm btn-success"
                                  @click.prevent="adicionarQuestaoAtividade(0, prova.id_prova, 'secretaria_avaliacao')"
                                >
                                  <small>+ &nbsp; Adicionar todas as questões na avaliação</small>
                                </button>
                              </div>
                              <div v-else>
                                <h6 class="text-success" />
                              </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
                          <table
                            v-if="prova.questoes"
                            class="table table-striped table-bordered"
                          >
                            <thead>
                              <tr class="bg-dark text-white">
                                <th
                                  style="width: 50px"
                                  scope="col"
                                >
                                  Id
                                </th>
                                <th scope="col">
                                  Questão
                                </th>
                                <th
                                  scope="col"
                                  class="text-center"
                                >
                                  Disciplina
                                </th>
                                <th scope="col">
                                  Alternativas
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Opções
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="prova.questoes.length">
                              <tr
                                v-for="questao in prova.pageOfQuestoes"
                                :key="questao.id_questao"
                              >
                                <td>#{{ questao.id_questao }}</td>
                                <td>
                                  <a
                                    href="#"
                                    @click.prevent="exibeModalEditarQuestao(questao)"
                                  >{{ removeHTMLpergunta(questao.pergunta, 80) }}</a>
                                </td>
                                <td class="text-center">
                                  {{ questao.disciplina }}
                                  <span
                                    v-if="!questao.id_plataforma_area_disciplina"
                                    class="text-danger"
                                  >Não configurada</span>
                                </td>
                                <td
                                  v-if="prova.tipo_prova != 'D'"
                                  class="text-center"
                                >
                                  <span v-if="questao.alternativas">{{ questao.alternativas.length }}</span>
                                  <p
                                    v-if="questao.questao_sem_correta"
                                    class="text-danger"
                                  >
                                    <small>Sem correta</small>
                                  </p>
                                </td>
                                <td
                                  v-else
                                  class="text-center"
                                >
                                  <small>Dissertativa</small>
                                </td>
                                <td class="text-center">
                                  <button
                                    v-if="fastAtividade.id_atividade && !verificaQuestaoNoQuiz(questao.id_questao) && prova.tipo_prova != 'D'"
                                    href="#"
                                    type="button"
                                    class="btn btn-sm btn-success mb-2"
                                    @click.prevent="adicionarQuestaoAtividade(questao.id_questao, 0, fastAtividade.tipo_atividade)"
                                  >
                                    <small v-if="fastAtividade.tipo_atividade == 'quiz'">Adicionar ao Quiz</small>
                                    <small v-else-if="fastAtividade.tipo_atividade == 'avaliacao'">Adicionar à Avaliação</small>
                                  </button>
                                  <button
                                    v-else-if="fastAtividade.id_atividade && verificaQuestaoNoQuiz(questao.id_questao) && prova.tipo_prova != 'D'"
                                    type="button"
                                    class="btn btn-sm btn-danger mb-2"
                                    @click.prevent="removerQuestaoAtividade(questao.id_questao, fastAtividade.tipo_atividade)"
                                  >
                                    <small v-if="fastAtividade.tipo_atividade == 'quiz'">Excluir do Quiz</small>
                                    <small v-else-if="fastAtividade.tipo_atividade == 'avaliacao'">Excluir da Avaliação</small>
                                  </button>
                                  <button
                                    v-else-if="fastSecretariaAvaliacao.id_secretaria_avaliacao > 0 && !verificaQuestaoSecretariaAvaliacao(questao.id_questao) && prova.tipo_prova != 'D'"
                                    type="button"
                                    class="btn btn-sm btn-success mb-2"
                                    @click.prevent="adicionarQuestaoAtividade(questao.id_questao, 0, 'secretaria_avaliacao')"
                                  >
                                    <small>Adicionar à Avaliação</small>
                                  </button>
                                  <button
                                    v-else-if="fastSecretariaAvaliacao.id_secretaria_avaliacao > 0 && verificaQuestaoSecretariaAvaliacao(questao.id_questao) && prova.tipo_prova != 'D'"
                                    type="button"
                                    class="btn btn-sm btn-danger mb-2"
                                    @click.prevent="removerQuestaoAtividade(questao.id_questao, 'secretaria_avaliacao')"
                                  >
                                    <small>Excluir da Avaliação</small>
                                  </button>
                                  <button
                                    class="btn btn-sm btn-success mb-2"
                                    @click="exibeModalEscolherDisciplina(questao)"
                                  >
                                    <small> <i
                                      class="fa fa-cog"
                                      aria-hidden="true"
                                    /> Disciplina </small>
                                  </button>
                                  <button
                                    href="#"
                                    type="button"
                                    class="btn btn-sm btn-danger mb-2"
                                    @click.prevent="exibeModalExcluirQuestao(questao)"
                                  >
                                    <small>Excluir da prova</small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-if="!prova.n_questoes">
                              <tr>
                                <td
                                  colspan="5"
                                  class="text-center"
                                >
                                  <h6>Nenhuma questão cadastrada</h6>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-if="prova.n_questoes && !prova.questoes.length">
                              <tr>
                                <td
                                  colspan="5"
                                  class="text-center"
                                >
                                  <button
                                    class="btn btn-primary btn-sm mt-2 ml-2 mb-2"
                                    @click.prevent="getFastPlataformaQuestoes(prova)"
                                  >
                                    <small>Exibir questões dessa prova</small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div
                            v-if="prova.questoes.length"
                            class="col-12 text-center mt-4"
                          >
                            <Pagination
                              :page-size="10"
                              :items="prova.questoes"
                              @changePage="prova.pageOfQuestoes = $event"
                            />
                          </div>
                        </div>
                      </div>
                    </tab>
                  </tabs>
                  <div
                    v-if="prova.alteracao_pendente"
                    class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                  >
                    <button
                      class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                      @click.prevent="confirmaAlteracaoProva(prova.id_prova)"
                    >
                      <small> <i
                        class="fa fa-floppy-o"
                        aria-hidden="true"
                      /> &nbsp;Salvar alterações </small>
                    </button>
                    <button
                      class="btn btn-secondary btn-sm mt-2 ml-2"
                      @click.prevent="getFastPlataformaProvas()"
                    >
                      <small> <i
                        class="fa fa-ban"
                        aria-hidden="true"
                      /> &nbsp;Cancelar </small>
                    </button>
                  </div>
                </div>
                <div class="col-12 text-center mt-4">
                  <Pagination
                    :page-size="10"
                    :items="provasFiltro"
                    @changePage="pageOfProvas = $event"
                  />
                </div>
              </div>
              <div
                v-else
                class="row"
              >
                <div class="col-12 mb-4 text-center">
                  <h4 v-text="msgCarregamentoProva" />
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <button
                    v-if="fastAtividade.id_atividade"
                    class="btn btn-danger ml-2 mt-2"
                    @click="voltarParaAtividade(fastAtividade.id_atividade)"
                  >
                    <b-icon-arrow-return-left />
                  Voltar
                  </button>
                  <button
                    v-if="fastSecretariaAvaliacao.id_secretaria_avaliacao > 0 && fastSecretariaAvaliacao.id_secretaria_avaliacao_uc > 0 && fastSecretariaAvaliacao.id_avaliacao_modelo"
                    class="btn btn-danger ml-2 mt-2"
                    @click="voltarParaSecretariaAvaliacao()"
                  >
                    <b-icon-arrow-return-left />
                  Voltar
                  </button>

                <a
                  v-else
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalExcluirProva"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir prova?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirProva')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <button
            class="btn btn-danger btn-sm mt-2 ml-2"
            @click.prevent="confirmaExcluirProva()"
          >
            <i
              class="fa fa-ban"
              aria-hidden="true"
            /> &nbsp;Confirmar
          </button>
        </div>
      </div>
    </modal>
    <modal
      name="modalCadastrarQuestao"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row text-left p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Cadastrar questão</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCadastrarQuestao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-12">
                  <h6>Insira sua questão:</h6>
                </div>
                <button
                  class="btn btn-sm btn-primary ml-3 mb-1"
                  @click="exibeModalAdicionarImagemQuestaoNovo(novaQuestao)"
                >
                  <small>Adicionar Imagem</small>
                </button>
                <div class="col-12">
                  <quill-editor
                    v-model="novaQuestao.pergunta"
                    :options="editorOption"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="row mt-4">
                <div class="col-6">
                  <h6>Alternativa</h6>
                </div>
                <div class="col-6 text-right">
                  <div class="fd-app-meus-cursos mt-0">
                    <div class="fd-app-curso-aulas-list mt-0">
                      <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-right">
                        <label
                          title="Correta?"
                          class="switch"
                        >
                          <input
                            v-model="novaQuestao.alternativas[0].correta"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-sm btn-primary ml-3 mb-1"
                  @click="exibeModalAdicionarImagemAlternativaNovo(0)"
                >
                  <small>Adicionar Imagem</small>
                </button>
                <div class="col-12">
                  <quill-editor
                    v-model="novaQuestao.alternativas[0].alternativa"
                    :options="editorOption"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="row mt-4">
                <div class="col-6">
                  <h6>Alternativa</h6>
                </div>
                <div class="col-6 text-right">
                  <div class="fd-app-meus-cursos mt-0">
                    <div class="fd-app-curso-aulas-list mt-0">
                      <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-right">
                        <label
                          title="Correta?"
                          class="switch"
                        >
                          <input
                            v-model="novaQuestao.alternativas[1].correta"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-sm btn-primary ml-3 mb-1"
                  @click="exibeModalAdicionarImagemAlternativaNovo(1)"
                >
                  <small>Adicionar Imagem</small>
                </button>
                <div class="col-12">
                  <quill-editor
                    v-model="novaQuestao.alternativas[1].alternativa"
                    :options="editorOption"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="row mt-4">
                <div class="col-6">
                  <h6>Alternativa</h6>
                </div>
                <div class="col-6 text-right">
                  <div class="fd-app-meus-cursos mt-0">
                    <div class="fd-app-curso-aulas-list mt-0">
                      <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-right">
                        <label
                          title="Correta?"
                          class="switch"
                        >
                          <input
                            v-model="novaQuestao.alternativas[2].correta"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-sm btn-primary ml-3 mb-1"
                  @click="exibeModalAdicionarImagemAlternativaNovo(2)"
                >
                  <small>Adicionar Imagem</small>
                </button>
                <div class="col-12">
                  <quill-editor
                    v-model="novaQuestao.alternativas[2].alternativa"
                    :options="editorOption"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="row mt-4">
                <div class="col-6">
                  <h6>Alternativa</h6>
                </div>
                <div class="col-6 text-right">
                  <div class="fd-app-meus-cursos mt-0">
                    <div class="fd-app-curso-aulas-list mt-0">
                      <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-right">
                        <label
                          title="Correta?"
                          class="switch"
                        >
                          <input
                            v-model="novaQuestao.alternativas[3].correta"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-sm btn-primary ml-3 mb-1"
                  @click="exibeModalAdicionarImagemAlternativaNovo(3)"
                >
                  <small>Adicionar Imagem</small>
                </button>
                <div class="col-12">
                  <quill-editor
                    v-model="novaQuestao.alternativas[3].alternativa"
                    :options="editorOption"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="row mt-4">
                <div class="col-6">
                  <h6>Alternativa</h6>
                </div>
                <div class="col-6 text-right">
                  <div class="fd-app-meus-cursos mt-0">
                    <div class="fd-app-curso-aulas-list mt-0">
                      <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-right">
                        <label
                          title="Correta?"
                          class="switch"
                        >
                          <input
                            v-model="novaQuestao.alternativas[4].correta"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-sm btn-primary ml-3 mb-1"
                  @click="exibeModalAdicionarImagemAlternativaNovo(4)"
                >
                  <small>Adicionar Imagem</small>
                </button>
                <div class="col-12">
                  <quill-editor
                    v-model="novaQuestao.alternativas[4].alternativa"
                    :options="editorOption"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12">
              <button
                href="#"
                type="button"
                class="btn btn-primary"
                @click.prevent="criaFastQuestao()"
              >
                Criar questao
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalCadastrarQuestaoDissertativa"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row text-left p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Cadastrar questão dissertativa</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCadastrarQuestaoDissertativa')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-12">
                  <h6>Insira sua questão:</h6>
                </div>
                <div class="col-12">
                  <quill-editor
                    v-model="novaQuestao.pergunta"
                    :options="editorOption"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12">
              <button
                href="#"
                type="button"
                class="btn btn-primary"
                @click.prevent="criaFastQuestaoDissertativa()"
              >
                Criar questao
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarQuestao"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Editar questão</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarQuestao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <div
            v-if="questaoEditar.id_questao"
            class="row"
          >
            <button
              class="btn btn-sm btn-primary ml-3 mb-1"
              @click="exibeModalAdicionarImagemQuestaoEdit(questaoEditar)"
            >
              <small>Adicionar Imagem</small>
            </button>
            <div class="col-sm-12 col-md-12 col-lg-12">
              <quill-editor
                v-model="questaoEditar.pergunta"
                :options="editorOption"
              />
            </div>
            <div class="row">
              <div
                v-if="questaoEditar.alternativas && questaoEditar.tipo_questao != 'D'"
                class="col-12"
              >
                <div class="row p-4">
                  <div
                    v-for="alternativa in questaoEditar.alternativas"
                    :key="alternativa.id_alternativa"
                    class="col-sm-12 col-md-12 col-lg-6 mb-4"
                  >
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-6">
                        <h6>Alternativa</h6>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                        <div class="fd-app-meus-cursos mt-0">
                          <div class="fd-app-curso-aulas-list mt-0">
                            <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-right">
                              <label
                                title="Correta?"
                                class="switch"
                              >
                                <input
                                  v-if="alternativa.correta"
                                  v-model="alternativa.correta"
                                  type="checkbox"
                                  checked="checked"
                                >
                                <input
                                  v-else
                                  v-model="alternativa.correta"
                                  type="checkbox"
                                >
                                <span class="slider round" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        class="btn btn-sm btn-primary ml-3 mb-1"
                        @click="exibeModalAdicionarImagemAlternativa(alternativa)"
                      >
                        <small>Adicionar Imagem</small>
                      </button>
                      
                      <div class="col-12">
                        <quill-editor
                          v-model="alternativa.alternativa"
                          :options="editorOption"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="questaoEditar.tipo_questao != 'D'"
                class="col-12 text-center"
              >
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary ml-2 mr-2"
                  @click.prevent="salvaAlteracoesQuestaoEditar()"
                >
                  Salvar alterações
                </button>
                <button
                  href="#"
                  type="button"
                  class="btn btn-success ml-2 mr-2"
                  @click.prevent="adicionaAlternativaEdicao()"
                >
                  <small>+ Alternativa</small>
                </button>
              </div>
              <div
                v-else
                class="col-12 text-center mt-4"
              >
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary ml-2 mr-2"
                  @click.prevent="salvaAlteracoesQuestaoDissertativaEditar()"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirQuestao"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir questão?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirQuestao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <button
            class="btn btn-danger btn-sm mt-2 ml-2"
            @click.prevent="confirmaExcluirQuestao()"
          >
            <i
              class="fa fa-ban"
              aria-hidden="true"
            /> &nbsp;Confirmar
          </button>
        </div>
      </div>
    </modal>
    <modal
      name="modalEscolherDisciplina"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Escolha uma disciplina</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEscolherDisciplina')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div>
                <vue-tree-list
                  :model="treeDisciplinas"
                  default-tree-node-name="Disciplina"
                  :default-expanded="false"
                  @delete-node="confirmaDisciplinaEscolhida"
                >
                  <template v-slot:leafNameDisplay="slotProps">
                    <span>{{ slotProps.model.name }}</span>
                  </template>
                  <span
                    slot="delNodeIcon"
                    title="Escolher essa disciplina"
                    style="cursor: pointer"
                    class="icon"
                  >
                    <button class="btn btn-sm btn-success pt-0 pb-0">
                      <small>+ Adicionar</small>
                    </button>
                  </span>
                  <span
                    slot="addTreeNodeIcon"
                    title="Escolher essa disciplina"
                    class="icon d-none"
                  />
                  <span
                    slot="addLeafNodeIcon"
                    class="icon d-none"
                  />
                  <span
                    slot="editNodeIcon"
                    title="Editar"
                    class="icon d-none"
                  />
                  <span
                    slot="treeNodeIcon"
                    class="icon"
                  >📚</span>
                </vue-tree-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalBuscarQuestoesConcurseiro"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row text-left p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Buscar questões</h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalBuscarQuestoesConcurseiro')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <div class="fd-app-wrap">
            <div class="container-fluid">
              <section class="fd-app-curso-aulas">
                <div class="fd-app-curso-aulas-list">
                  <div class="container-fluid">
                    <div class="row text-center mb-4 pb-4">
                      <div class="t-questoes-filtro d-flex justify-content-center border-bottom pb-4 flex-wrap">
                        <div class="font-weight-bold pl-2">
                          <label>Palavra chave</label>
                          <input
                            v-model="concurseiroQuestoesFiltro.pergunta"
                            class="form-control"
                            type="text"
                          >
                        </div>
                        <div class="font-weight-bold pl-2">
                          <label>Prova</label>
                          <input
                            v-model="concurseiroQuestoesFiltro.id_referencia"
                            class="form-control"
                            type="number"
                            value
                          >
                        </div>
                        <div class="font-weight-bold pl-2">
                          <label>Questão</label>
                          <input
                            v-model="concurseiroQuestoesFiltro.id_questao"
                            class="form-control"
                            type="number"
                          >
                        </div>
                        <div class="font-weight-bold pl-2">
                          <label>Disciplina</label>
                          <select v-model="concurseiroQuestoesFiltro.id_area_disciplina" class="form-control pr-4" id="t-questoes-id-area-disciplina" style="width: 300px">
                            <option value="">-- Disciplina --</option>
                            <option value="1718">Absolutismo </option>
                            <option value="104">Acústica</option>
                            <option value="1492">Acústica </option>
                            <option value="1810">Adaptação e Evolução</option>
                            <option value="1028">Adequação Ambiental</option>
                            <option value="957">Administração</option>
                            <option value="959">Administração da Produção</option>
                            <option value="968">Administração de Pessoal</option>
                            <option value="963">Administração de Recursos Humanos</option>
                            <option value="1248">Administração de Sistemas Educacionais</option>
                            <option value="960">Administração Financeira</option>
                            <option value="1407">Administração Geral </option>
                            <option value="1341">Administração Hospitalar</option>
                            <option value="964">Administração Pública</option>
                            <option value="3">Álgebra</option>
                            <option value="8">Algebra Comutativa</option>
                            <option value="10">Análise</option>
                            <option value="1536">Análise Combinatória </option>
                            <option value="11">Análise Complexa</option>
                            <option value="46">Análise de Dados</option>
                            <option value="1293">Análise Institucional</option>
                            <option value="28">Análise Numérica</option>
                            <option value="1193">Análise Regional</option>
                            <option value="1551">Ângulos </option>
                            <option value="1160">Antropologia</option>
                            <option value="1165">Antropologia das Populações Afro-Brasileiras</option>
                            <option value="1164">Antropologia Rural</option>
                            <option value="1163">Antropologia Urbana</option>
                            <option value="1408">Arquivologia</option>
                            <option value="1305">Artes</option>
                            <option value="1655">Artrópodes </option>
                            <option value="1287">Atitude e Ideologias Políticas</option>
                            <option value="1425">Atomística</option>
                            <option value="1464">Atrito</option>
                            <option value="1403">Auditoria</option>
                            <option value="349">Bacterologia</option>
                            <option value="1612">Bioenergética </option>
                            <option value="327">Biofísica Celular</option>
                            <option value="1413">Biogeografia</option>
                            <option value="252">Biologia Geral</option>
                            <option value="1435">Biologia Molecular</option>
                            <option value="321">Bioquímica dos Microorganismos</option>
                            <option value="268">Botânica</option>
                            <option value="260">Botânica</option>
                            <option value="283">Botânica Aplicada</option>
                            <option value="1741">Brasil após Ditadura</option>
                            <option value="1731">Brasil Pré-Colonial</option>
                            <option value="1438">Calorimetria</option>
                            <option value="1597">Características dos Compostos Orgânicos </option>
                            <option value="224">Cartografia Básica</option>
                            <option value="1315">Cerâmica</option>
                            <option value="1611">Ciclo Celular, Divisão Celular e Gametogênese</option>
                            <option value="1820">Ciclos Biogeoquímicos </option>
                            <option value="1345">Ciências</option>
                            <option value="250">Ciências Biológicas</option>
                            <option value="1">Ciências Exatas e da Terra</option>
                            <option value="1689">Ciências sociais e o Cotidiano</option>
                            <option value="1560">Cilindros </option>
                            <option value="1827">Cinemática </option>
                            <option value="1459">Cinemática - Lançamentos </option>
                            <option value="1444">Cinemática Escalar </option>
                            <option value="1590">Cinética Química </option>
                            <option value="166">Cinética Química e Catálise</option>
                            <option value="1556">Círculo e Cincurferência </option>
                            <option value="1427">Citologia</option>
                            <option value="299">Citologia e Biologia Celular</option>
                            <option value="1416">Climatologia</option>
                            <option value="1404">Comércio Internacional </option>
                            <option value="1289">Comportamento Legislativo</option>
                            <option value="1831">Conceitos Básicos </option>
                            <option value="1568">Conceitos Básicos de Geometria Analítica</option>
                            <option value="1542">Conceitos Básicos, Frequências e Gráficos </option>
                            <option value="1488">Conceitos Fundamentais </option>
                            <option value="1288">Conflitos e Coalizões Políticas</option>
                            <option value="1405">Conhecimentos Bancários</option>
                            <option value="1368">Conhecimentos Específicos</option>
                            <option value="1367">Conhecimentos Gerais</option>
                            <option value="1823">Conhecimentos Numéricos</option>
                            <option value="4">Conjuntos</option>
                            <option value="820">Conservação de Áreas Silvestres</option>
                            <option value="1828">Conservação de Energia</option>
                            <option value="1401">Contabilidade</option>
                            <option value="965">Contabilidade e Financas Públicas</option>
                            <option value="1615">Controle Celular </option>
                            <option value="1616">Controle Celular </option>
                            <option value="1694">Culturas e Sociedade</option>
                            <option value="1812">Darwinismo</option>
                            <option value="1351">Desenho de Projetos</option>
                            <option value="115">Desintegração Nuclear e Radioatividade</option>
                            <option value="1480">Dilatação Térmica </option>
                            <option value="1469">Dinâmica do Movimento Circular</option>
                            <option value="933">Direito</option>
                            <option value="949">Direito Administrativo</option>
                            <option value="1393">Direito Ambiental</option>
                            <option value="952">Direito Civil</option>
                            <option value="953">Direito Comercial</option>
                            <option value="948">Direito Constitucional</option>
                            <option value="1392">Direito do Consumidor</option>
                            <option value="954">Direito do Trabalho</option>
                            <option value="1389">Direito Empresarial</option>
                            <option value="1400">Direito Financeiro</option>
                            <option value="955">Direito Internacional Privado</option>
                            <option value="950">Direito Internacional Público</option>
                            <option value="945">Direito Penal</option>
                            <option value="1399">Direito Previdenciário</option>
                            <option value="947">Direito Processual Civil</option>
                            <option value="1394">Direito Processual do Trabalho</option>
                            <option value="946">Direito Processual Penal</option>
                            <option value="943">Direito Público</option>
                            <option value="944">Direito Tributário</option>
                            <option value="956">Direitos Especiais</option>
                            <option value="1390">Direitos Humanos</option>
                            <option value="1740">Ditadura Militar</option>
                            <option value="1391">ECA</option>
                            <option value="294">Ecologia</option>
                            <option value="297">Ecologia Aplicada</option>
                            <option value="296">Ecologia de Ecossistemas</option>
                            <option value="1780">Ecologia de Populações </option>
                            <option value="295">Ecologia Teórica</option>
                            <option value="1009">Economia do Consumidor</option>
                            <option value="1008">Economia dos Programas de Bem-Estar Social</option>
                            <option value="755">Educação Física</option>
                            <option value="1711">Egito Antigo</option>
                            <option value="1440">Eletricidade</option>
                            <option value="102">Eletricidade e Magnetismo; Campos e Partículas Carregadas</option>
                            <option value="1441">Eletrodinâmica </option>
                            <option value="1477">Eletromagnetismo </option>
                            <option value="167">Eletroquímica</option>
                            <option value="1641">Embriologia </option>
                            <option value="1428">Envoltórios Celulares</option>
                            <option value="324">Enzimologia</option>
                            <option value="1512">Equações do 1º Grau</option>
                            <option value="1517">Equações do 2º Grau.</option>
                            <option value="1431">Equilíbrio Químico</option>
                            <option value="1724">Era das Navegações </option>
                            <option value="1738">Era Vargas</option>
                            <option value="1314">Escultura</option>
                            <option value="1563">Esferas</option>
                            <option value="1484">Espelhos Esféricos  </option>
                            <option value="1705">Estado</option>
                            <option value="1541">Estatística</option>
                            <option value="38">Estatística</option>
                            <option value="1822">Estatuto da Advocacia e da OAB</option>
                            <option value="1433">Estequiometria</option>
                            <option value="1280">Estrutura e Transformação do Estado</option>
                            <option value="150">Estrutura, Conformação e Estereoquímica</option>
                            <option value="1610">Estudo do Núcleo </option>
                            <option value="1283">Estudos do Poder Local</option>
                            <option value="1286">Estudos Eleitorais e Partidos Políticos</option>
                            <option value="1358">Estudos Sociais</option>
                            <option value="1126">Ética</option>
                            <option value="1663">Ètica Aplicada: Ética Social</option>
                            <option value="1658">Ètica Normativa</option>
                            <option value="1809">Evolução</option>
                            <option value="155">Evolução, Sistemática e Ecologia Química</option>
                            <option value="1323">Execução da Dança</option>
                            <option value="1511">Expressões Algébricas e Fatoração Algébrica </option>
                            <option value="1503">Expressões Numéricas</option>
                            <option value="1710">Fenícios e Hebreus</option>
                            <option value="1613">Fermentação e Respiração Celular </option>
                            <option value="1716">Feudalismo</option>
                            <option value="1398">Filosofia </option>
                            <option value="1122">Filosofia</option>
                            <option value="1669">Filosofia Antiga</option>
                            <option value="1128">Filosofia Brasileira</option>
                            <option value="1672">Filosofia Contemporânea </option>
                            <option value="1677">Filosofia da Ciência </option>
                            <option value="1673">Filosofia da Mente</option>
                            <option value="1685">Filosofia da Mente e Fé </option>
                            <option value="1687">Filosofia da Religião </option>
                            <option value="939">Filosofia do Direito</option>
                            <option value="1671">Filosofia Moderna </option>
                            <option value="1668">Filosofia Política </option>
                            <option value="1406">Financeira</option>
                            <option value="93">Física</option>
                            <option value="120">Física Atômica e Molécular</option>
                            <option value="94">Física Geral</option>
                            <option value="163">Fisico Química Inorgânica</option>
                            <option value="165">Fisico-Química</option>
                            <option value="152">Fisico-Química Orgânica</option>
                            <option value="1132">Fisiologia da Linguagem</option>
                            <option value="306">Fisiologia de Órgaos e Sistemas</option>
                            <option value="305">Fisiologia Geral</option>
                            <option value="1570">Fórmulas de Distância e Area</option>
                            <option value="1752">Fotossíntese e Quimiossíntese</option>
                            <option value="1506">Frações, Decimais e Dízimas </option>
                            <option value="1525">Função do 1º Grau.</option>
                            <option value="1526">Função do 2º Grau</option>
                            <option value="1529">Função Exponencial </option>
                            <option value="1450">Função Trigonométrica </option>
                            <option value="1829">Funções Orgânicas </option>
                            <option value="39">Fundamentos da Estatística</option>
                            <option value="1587">Gases</option>
                            <option value="251">Genética</option>
                            <option value="1380">Genética Animal</option>
                            <option value="1760">Genética de Populações </option>
                            <option value="1381">Genética Humana e Médica</option>
                            <option value="1414">Geocartografia</option>
                            <option value="1183">Geografia</option>
                            <option value="1186">Geografia Agrária</option>
                            <option value="1185">Geografia da População</option>
                            <option value="1188">Geografia Econômica</option>
                            <option value="1412">Geografia Física</option>
                            <option value="225">Geografia Física</option>
                            <option value="1184">Geografia Humana</option>
                            <option value="1189">Geografia Política</option>
                            <option value="1190">Geografia Regional</option>
                            <option value="1187">Geografia Urbana</option>
                            <option value="17">Geometria e Topologia</option>
                            <option value="1452">Geometria Espacial</option>
                            <option value="1557">Geometria Espacial de Posição </option>
                            <option value="1550">Geometria Plana</option>
                            <option value="1417">Geomorfologia</option>
                            <option value="1418">Geopolítica </option>
                            <option value="1751">Globalização </option>
                            <option value="1712">Grécia Antiga </option>
                            <option value="1750">Guerra Fria</option>
                            <option value="1415">Hidrogeografia</option>
                            <option value="563">Higiene e Segurança do Trabalho</option>
                            <option value="1170">História</option>
                            <option value="1708">História Antiga</option>
                            <option value="1172">História Antiga e Medieval</option>
                            <option value="1742">História Contemporânea</option>
                            <option value="1174">História da América</option>
                            <option value="1726">História da América Latina</option>
                            <option value="1308">História da Arte</option>
                            <option value="1153">História da Sociologia</option>
                            <option value="1182">História das Ciências</option>
                            <option value="1177">História do Brasil</option>
                            <option value="1178">História do Brasil Colônia</option>
                            <option value="1179">História do Brasil Império</option>
                            <option value="1180">História do Brasil República</option>
                            <option value="938">História do Direito</option>
                            <option value="1175">História dos Estados Unidos</option>
                            <option value="1176">História Latino-Americana</option>
                            <option value="1714">História Medieval </option>
                            <option value="1721">História Moderna </option>
                            <option value="1173">História Moderna e Contemporânea</option>
                            <option value="1430">Impactos Ambientais</option>
                            <option value="1719">Império Bizantino</option>
                            <option value="343">Imunologia Celular</option>
                            <option value="342">Imunoquímica</option>
                            <option value="1528">Inequações -  2º Grau</option>
                            <option value="1284">Instituições Governamentais Específicas</option>
                            <option value="1298">Integração Internacional, Conflito, Guerra e Paz</option>
                            <option value="1451">Interpretação de Gráfico </option>
                            <option value="1715">Invasões Bárbaras </option>
                            <option value="1366">Legislação</option>
                            <option value="558">Legislação Ambiental</option>
                            <option value="1054">Legislação Urbana e Regional</option>
                            <option value="1461">Leis de Newton </option>
                            <option value="1137">Letras</option>
                            <option value="1422">Ligações Intermoleculares</option>
                            <option value="1423">Ligações Químicas</option>
                            <option value="1442">Ligações Químicas</option>
                            <option value="1411">Língua Espanhola</option>
                            <option value="1410">Língua Inglesa</option>
                            <option value="1138">Língua Portuguesa</option>
                            <option value="59">Linguagens de Programação</option>
                            <option value="1139">Línguas Estrangeiras Modernas</option>
                            <option value="1130">Lingüística</option>
                            <option value="1129">Lingüística, Letras e Artes</option>
                            <option value="1143">Literatura Brasileira</option>
                            <option value="1146">Literaturas Clássicas</option>
                            <option value="1145">Literaturas Estrangeiras Modernas</option>
                            <option value="1448">Logaritmos</option>
                            <option value="1125">Lógica</option>
                            <option value="5">Lógica Matemática</option>
                            <option value="1476">Magnetismo </option>
                            <option value="2">Matemática</option>
                            <option value="26">Matemática Aplicada</option>
                            <option value="1501">Matemática Básica l </option>
                            <option value="1510">Matemática Básica ll</option>
                            <option value="29">Matemática Discreta e Combinatoria</option>
                            <option value="1532">Matrizes </option>
                            <option value="1457">Mecânica </option>
                            <option value="1443">Mecânica</option>
                            <option value="106">Mecânica, Elasticidade e Reologia</option>
                            <option value="719">Medicina Legal e Deontologia</option>
                            <option value="1429">Membrana Plasmátca</option>
                            <option value="1777">Membrana Plasmática </option>
                            <option value="1709">Mesopotâmia </option>
                            <option value="322">Metabolismo e Bioenergética</option>
                            <option value="1044">Métodos e Técnicas do Planejamento Urbano e Regional</option>
                            <option value="346">Microbiologia</option>
                            <option value="262">Morfologia Vegetal</option>
                            <option value="1460">Movimento Circular</option>
                            <option value="1825">Movimento Uniforme </option>
                            <option value="1826">Movimento Uniforme Variado</option>
                            <option value="1692">Mudança social e cidadania</option>
                            <option value="1149">Noções de Informática</option>
                            <option value="1508">Notação Científica </option>
                            <option value="1778">Núcleo</option>
                            <option value="1539">Números Complexos </option>
                            <option value="276">Nutrição e Crescimento Vegetal</option>
                            <option value="1424">Ondulatória </option>
                            <option value="1502">Operações com Números Naturais</option>
                            <option value="1439">Optica</option>
                            <option value="1487">Óptica da Visão e Instrumentos Ópticos </option>
                            <option value="1779">Organelas</option>
                            <option value="1297">Organizações Internacionais</option>
                            <option value="966">Organizações Públicas</option>
                            <option value="103">Ótica</option>
                            <option value="1159">Outras Sociologias Específicas</option>
                            <option value="1339">Outros</option>
                            <option value="1531">P.A  e P.G</option>
                            <option value="1561">Pirâmides </option>
                            <option value="1446">Polialelia e Grupos Sanguíneos</option>
                            <option value="1565">Poliedros</option>
                            <option value="1555">Polígonos Regulares </option>
                            <option value="1540">Polinômios </option>
                            <option value="967">Política e Planejamento Governamentais</option>
                            <option value="1296">Política Externa do Brasil</option>
                            <option value="1523">Porcentagem</option>
                            <option value="1648">Poríferos </option>
                            <option value="1739">Pós Vargas</option>
                            <option value="1572">Posição Relativa </option>
                            <option value="1507">Potenciação e Radiciação nos Números Reais </option>
                            <option value="1746">Primeira Guerra Mundial</option>
                            <option value="1734">Primeiro Reinado</option>
                            <option value="1462">Principais Forças da Mecânica </option>
                            <option value="1559">Prismas</option>
                            <option value="31">Probabilidade</option>
                            <option value="30">Probabilidade e Estatística</option>
                            <option value="47">Probabilidade e Estatística Aplicadas</option>
                            <option value="1538">Probabilidades</option>
                            <option value="1421">Problemas Fisiológicos</option>
                            <option value="35">Processos Markovianos</option>
                            <option value="1479">Propagação do Calor </option>
                            <option value="1432">Propriedades Coligativas</option>
                            <option value="1436">Propriedades Periódicas</option>
                            <option value="356">Protozoologia Parasitária Humana</option>
                            <option value="1135">Psicolingüística</option>
                            <option value="1849">Psicologia Aplicada a Enfermagem</option>
                            <option value="1554">Quadriláteros Notáveis </option>
                            <option value="148">Química</option>
                            <option value="1397">Química </option>
                            <option value="174">Química Analítica</option>
                            <option value="1395">Química Geral</option>
                            <option value="1396">Química Geral</option>
                            <option value="157">Química Inorgânica</option>
                            <option value="149">Química Orgânica</option>
                            <option value="1365">Raciocínio Lógico</option>
                            <option value="1426">Radioatividade</option>
                            <option value="1521">Razões e Proporções </option>
                            <option value="1419">Reação de Combustão</option>
                            <option value="1584">Reações Inorgânicas </option>
                            <option value="1445">Reações Orgânicas</option>
                            <option value="1604">Reações Orgânicas de Álcoois  </option>
                            <option value="1600">Reações Orgânicas de Substituição</option>
                            <option value="1364">Redação - Atualidades</option>
                            <option value="1489">Reflexão, Refração e Difração </option>
                            <option value="1722">Reformas Protestantes</option>
                            <option value="1485">Refração </option>
                            <option value="1748">Regimes Totalitários </option>
                            <option value="1192">Regionalização</option>
                            <option value="1520">Regra de Três </option>
                            <option value="1770">Reino Animalia </option>
                            <option value="1769">Reino Plantae </option>
                            <option value="1420">Reinos</option>
                            <option value="1704">Relações de Poder</option>
                            <option value="1299">Relações Internacionais, Bilaterais e Multilaterais </option>
                            <option value="1723">Renascimento </option>
                            <option value="1737">República Velha</option>
                            <option value="1490">Ressonância, Polarização e Efeito Doppler </option>
                            <option value="1730">Revolução Francesa</option>
                            <option value="1729">Revolução Industrial </option>
                            <option value="1747">Revolução Russa</option>
                            <option value="1713">Roma Antiga</option>
                            <option value="751">Saúde Publica</option>
                            <option value="1749">Segunda Guerra Mundial</option>
                            <option value="1736">Segundo Reinado</option>
                            <option value="175">Separação</option>
                            <option value="1596">Séries Orgânicas </option>
                            <option value="1792">Sistema Excretor</option>
                            <option value="1621">Sistema Respiratório </option>
                            <option value="1630">Sistema Sensorial </option>
                            <option value="1516">Sistemas de Equações do 1º Grau.</option>
                            <option value="62">Sistemas de Informação</option>
                            <option value="1522">Sistemas de Medidas</option>
                            <option value="977">Sistemas Econômicos</option>
                            <option value="1150">Sociologia</option>
                            <option value="1690">Sociologia como ciência da sociedade</option>
                            <option value="1154">Sociologia do Conhecimento</option>
                            <option value="1155">Sociologia do Desenvolvimento</option>
                            <option value="1157">Sociologia Rural</option>
                            <option value="1156">Sociologia Urbana</option>
                            <option value="1589">Soluções </option>
                            <option value="1580">Substâncias Puras e Misturas </option>
                            <option value="1848">Técnicas Básicas de Enfermagem</option>
                            <option value="1191">Teoria do Desenvolvimento Regional</option>
                            <option value="6">Teoria dos Números</option>
                            <option value="1131">Teoria e Análise Lingüística</option>
                            <option value="1171">Teoria e Filosofia da História</option>
                            <option value="974">Teoria Geral da Economia</option>
                            <option value="1083">Teoria Geral da Informação</option>
                            <option value="935">Teoria Geral do Direito</option>
                            <option value="32">Teoria Geral e Fundamentos da Probabilidade</option>
                            <option value="1152">Teoria Sociológica</option>
                            <option value="1481">Termodinâmica </option>
                            <option value="173">Termodinâmica Química</option>
                            <option value="1437">Termologia</option>
                            <option value="1478">Termometria </option>
                            <option value="1434">Termoquímica</option>
                            <option value="1586">Tipos de Fórmulas Químicas </option>
                            <option value="179">Titimetria</option>
                            <option value="1470">Trabalho, Energia e Potência </option>
                            <option value="105">Transferência de Calor; Processos Térmicos e Termodinâmicos</option>
                            <option value="1552">Triângulos</option>
                            <option value="1449">Trigonometria </option>
                            <option value="1636">Visão Geral e Tecido Epitelial </option>
                            <option value="1558">Vistas</option>
                            <option value="284">Zoologia</option>
                          </select>
                        </div>
                        <div class="font-weight-bold pl-2">
                          <label>Ano</label>
                          <select
                            v-model="concurseiroQuestoesFiltro.ano"
                            class="form-control pr-4"
                          >
                            <option value>
                              -- Ano --
                            </option>
                            <option value="2017">
                              2017
                            </option>
                            <option
                              v-for="year in years"
                              :key="year"
                              :value="year"
                            >
                              {{ year }}
                            </option>
                          </select>
                        </div>
                        <div class="font-weight-bold pl-2">
                          <label>Questões por página</label>
                          <select
                            v-model="concurseiroQuestoesFiltro.nQuestoes"
                            class="form-control pr-4"
                          >
                            <option value="10">
                              10
                            </option>
                            <option value="20">
                              20
                            </option>
                            <option value="30">
                              30
                            </option>
                            <option value="40">
                              40
                            </option>
                            <option value="50">
                              50
                            </option>
                            <option value="100">
                              100
                            </option>
                          </select>
                        </div>
                        <div class="font-weight-bold mt-4 pl-2 d-flex flex-column justify-content-end">
                          <button
                            id="btn-busca-questoes"
                            class="btn btn-primary"
                            @click="getConcurseiroQuestoes()"
                          >
                            Pesquisar
                          </button>
                        </div>
                      </div>
                      <div class="col-12 t-questoes-pesquisou mt-4">
                        <h4>Questões encontradas: {{ concurseiroQuestoesFiltro.total }}</h4>
                        <h6 v-if="concurseiroQuestoesFiltro.total">
                          Página {{ concurseiroQuestoesFiltro.pagina }} de {{ concurseiroQuestoesFiltro.totalPaginas }}
                        </h6>
                      </div>
                      <div class="col-12 t-questoes-pesquisou mt-4">
                        <div class="row t-questoes-resultados bg-light">
                          <div
                            v-for="questao in concurseiroQuestoes"
                            :key="questao.id_questao"
                            class="col-sm-12 col-md-12 col-lg-12 text-left"
                          >
                            <div class="shadow p-3 mb-4 bg-white rounded">
                              <div class="d-flex">
                                <div class="font-weight-bold pr-2">
                                  <span>Questão #{{ questao.id_questao }}</span>
                                </div>
                                <div class="pl-2 pr-2 font-weight-bold border-left">
                                  <span>Prova: #{{ questao.id_referencia }}</span>
                                </div>
                                <div class="pl-2 pr-2 font-weight-bold border-left">
                                  <span>Disciplina: #{{ questao.descricao }}</span>
                                </div>
                                <div class="pl-2 pr-2 font-weight-bold border-left">
                                  <a
                                    href="#"
                                    class="text-success"
                                    @click.prevent="adicionaQuestaoConcurseiro(questao)"
                                  > <b-icon-plus />&nbsp;Adicionar questão </a>
                                </div>
                              </div>
                              <div>{{ removeHTMLpergunta(questao.pergunta, 500) }}</div>
                            </div>
                          </div>
                        </div>

                        <div
                          v-if="concurseiroQuestoesFiltro.totalPaginas"
                          class="row text-center t-questoes-paginacao"
                        >
                          <div
                            class="d-flex justify-content-center w-100 mt-2"
                          >
                            <ul
                              v-if="concurseiroQuestoesFiltro.pagina < 3"
                              class="d-flex list-inline"
                            >
                              <li
                                v-for="index in 4"
                                :key="index"
                                :class="'page-item ' + classePaginacao(index)"
                              >
                                <span
                                  v-if="index == concurseiroQuestoesFiltro.pagina"
                                  class="page-link"
                                >{{ index }}</span>
                                <span
                                  v-else
                                  class="page-link"
                                  @click="getConcurseiroQuestoes(index)"
                                >{{ index }}</span>
                              </li>
                              <li class="page-item">
                                <span class="page-link">...</span>
                              </li>
                              <li class="page-item">
                                <span
                                  class="page-link"
                                  @click="getConcurseiroQuestoes(concurseiroQuestoesFiltro.totalPaginas)"
                                >{{ concurseiroQuestoesFiltro.totalPaginas }}</span>
                              </li>
                              <li class="page-item next">
                                <span
                                  class="page-link"
                                  @click="getConcurseiroQuestoes(concurseiroQuestoesFiltro.pagina + 1)"
                                >Próximo</span>
                              </li>
                            </ul>
                            <ul
                              v-else-if="concurseiroQuestoesFiltro.pagina > concurseiroQuestoesFiltro.totalPaginas - 2"
                              class="d-flex list-inline"
                            >
                              <li class="page-item previous">
                                <span
                                  class="page-link"
                                  @click="getConcurseiroQuestoes(concurseiroQuestoesFiltro.pagina - 1)"
                                >Anterior</span>
                              </li>
                              <li class="page-item">
                                <span
                                  class="page-link"
                                  @click="getConcurseiroQuestoes(1)"
                                >1</span>
                              </li>
                              <li class="page-item">
                                <span class="page-link">...</span>
                              </li>
                              <li class="page-item">
                                <span
                                  class="page-link"
                                  @click="getConcurseiroQuestoes(concurseiroQuestoesFiltro.totalPaginas - 2)"
                                >{{ concurseiroQuestoesFiltro.totalPaginas - 2 }}</span>
                              </li>

                              <li
                                v-for="pagina in [concurseiroQuestoesFiltro.totalPaginas - 1, concurseiroQuestoesFiltro.totalPaginas]"
                                :key="pagina"
                                :class="'page-item ' + classePaginacao(pagina)"
                              >
                                <span
                                  v-if="pagina == concurseiroQuestoesFiltro.pagina"
                                  class="page-link"
                                >{{ pagina }}</span>
                                <span
                                  v-else
                                  class="page-link"
                                  @click="getConcurseiroQuestoes(pagina)"
                                >{{ pagina }}</span>
                              </li>
                            </ul>
                            <ul
                              v-else
                              class="d-flex list-inline"
                            >
                              <li class="page-item previous">
                                <span
                                  class="page-link"
                                  @click="getConcurseiroQuestoes(concurseiroQuestoesFiltro.pagina - 1)"
                                >Anterior</span>
                              </li>
                              <li class="page-item">
                                <span
                                  class="page-link"
                                  @click="getConcurseiroQuestoes(1)"
                                >1</span>
                              </li>
                              <li class="page-item">
                                <span class="page-link">...</span>
                              </li>
                              <li class="page-item">
                                <span
                                  class="page-link"
                                  @click="getConcurseiroQuestoes(concurseiroQuestoesFiltro.pagina - 1)"
                                >{{ concurseiroQuestoesFiltro.pagina - 1 }}</span>
                              </li>
                              <li class="page-item active">
                                <span
                                  style="color: #fff;"
                                  class="page-link"
                                >{{ concurseiroQuestoesFiltro.pagina }}</span>
                              </li>
                              <li class="page-item">
                                <span
                                  class="page-link"
                                  @click="getConcurseiroQuestoes(concurseiroQuestoesFiltro.pagina + 1)"
                                >{{ concurseiroQuestoesFiltro.pagina + 1 }}</span>
                              </li>
                              <li class="page-item">
                                <span class="page-link">...</span>
                              </li>
                              <li class="page-item">
                                <span
                                  class="page-link"
                                  @click="getConcurseiroQuestoes(concurseiroQuestoesFiltro.totalPaginas)"
                                >{{ concurseiroQuestoesFiltro.totalPaginas }}</span>
                              </li>
                              <li class="page-item next">
                                <span
                                  class="page-link"
                                  @click="getConcurseiroQuestoes(concurseiroQuestoesFiltro.pagina + 1)"
                                >Próximo</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalAdicionarImagemQuestaoEdit"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Adicionar Imagem</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalAdicionarImagemQuestaoEdit')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <!-- Upload aqui -->
                  <input
                    id="image_questao"
                    type="file"
                    class="custom-file-input"
                    accept="image/*"
                    @change="setImageQuestaoEdit"
                  >
                    <label
                      class="custom-file-label text-left"
                      for="image_questao"
                    ><b-icon icon="file-earmark-image"></b-icon> Inserir imagem...</label>
                    <div class="invalid-feedback">
                      Arquivo inválido
                    </div>
                    <!-- /Upload aqui -->
                  </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalAdicionarImagemQuestaoNovo"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Adicionar Imagem</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalAdicionarImagemQuestaoNovo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <!-- Upload aqui -->
                  <input
                    id="image_questao"
                    type="file"
                    class="custom-file-input"
                    accept="image/*"
                    @change="setImageQuestaoNovo"
                  >
                    <label
                      class="custom-file-label text-left"
                      for="image_questao"
                    ><b-icon icon="file-earmark-image"></b-icon> Inserir imagem...</label>
                    <div class="invalid-feedback">
                      Arquivo inválido
                    </div>
                    <!-- /Upload aqui -->
                  </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalAdicionarImagemAlternativaEdit"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Adicionar Imagem</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalAdicionarImagemAlternativaEdit')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <!-- Upload aqui -->
                  <input
                    id="image_alternativa"
                    type="file"
                    class="custom-file-input"
                    accept="image/*"
                    @change="setImageAlternativaEdit"
                  >
                    <label
                      class="custom-file-label text-left"
                      for="image_alternativa"
                    ><b-icon icon="file-earmark-image"></b-icon> Inserir imagem...</label>
                    <div class="invalid-feedback">
                      Arquivo inválido
                    </div>
                    <!-- /Upload aqui -->
                  </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalAdicionarImagemAlternativaNovo"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Adicionar Imagem</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalAdicionarImagemAlternativaNovo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <!-- Upload aqui -->
                  <input
                    id="image_alternativa"
                    type="file"
                    class="custom-file-input"
                    accept="image/*"
                    @change="setImageAlternativaNovo"
                  >
                    <label
                      class="custom-file-label text-left"
                      for="image_alternativa"
                    ><b-icon icon="file-earmark-image"></b-icon> Inserir imagem...</label>
                    <div class="invalid-feedback">
                      Arquivo inválido
                    </div>
                    <!-- /Upload aqui -->
                  </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { VueTreeList, Tree, TreeNode } from "vue-tree-list"; //https://github.com/ParadeTo/vue-tree-list#readme

export default {
  name: "HomeInternoAdminPersonalizarProvas",
  components: {
    Pagination,
    quillEditor,
    VueTreeList,
  },
  mixins: [methods],
  data: function() {
    return {
      indiceAlternativaAtual: "",
      msgCarregamentoProva: "Carregando provas...",
      // Provas
      provasTotal: [],
      provasFiltro: [],
      pageOfProvas: [],
      // Nova prova
      novaProva: {
        nome_prova: "",
        tipo_prova: "O"
      },
      // Excluir prova
      excluirProva: [],
      // Excluir questão
      excluirQuestao: [],
      // Nova questão
      novaQuestao: {
        id_prova: "",
        pergunta: "",
        tipo_questao: "O",
        alternativas: [
          {
            alternativa: "",
            correta: false,
          },
          {
            alternativa: "",
            correta: false,
          },
          {
            alternativa: "",
            correta: false,
          },
          {
            alternativa: "",
            correta: false,
          },
          {
            alternativa: "",
            correta: false,
          },
        ],
      },
      // Questão editada
      questaoEditar: {},
      questaoEditarImagem: {},
      // Editor de texto
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
          ],
        },
      },
      // Atividade de aula
      fastAtividade: {
        id_atividade: 0,
        tipo_atividade: "",
        questoes: [],
      },
      fastSecretariaAvaliacao: {
        id_secretaria_avaliacao: 0,
        id_secretaria_avaliacao_uc: 0,
        id_avaliacao_modelo: 0,
        questoes: []
      },
      // Disciplinas
      treeDisciplinas: new Tree({
        name: "Primeira disciplina",
        id: 0,
        isLeaf: false,
        addLeafNodeDisabled: true,
      }),
      modalWidth: "80%",
      concurseiroQuestoes: [],
      concurseiroQuestoesFiltro: {
        total: 0,
        ano: "",
        pergunta: "",
        id_questao: "",
        id_area_disciplina: "",
        id_referencia: "",
        id_banca: "",
        id_cargo: "",
        nivel: "",
        pagina: 1,
        nQuestoes: 10,
        totalPaginas: 0,
      },
      
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
    };
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2017}, (value, index) => 2018 + index)
    }
  },
  mounted: function() {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
         if (this.getUrlParameter("atividade") && this.getUrlParameter("tipo")) {
            this.fastAtividade.id_atividade = this.getUrlParameter("atividade");
            this.fastAtividade.tipo_atividade = this.getUrlParameter("tipo");
            if (this.fastAtividade.tipo_atividade == "quiz") {
              this.getQuestoesAtividadeQuiz();
            } else if (this.fastAtividade.tipo_atividade == "avaliacao") {
              this.getQuestoesAtividadeAvaliacao();
            }
          } else if (this.getUrlParameter("id_secretaria_avaliacao")){
            this.fastSecretariaAvaliacao.id_secretaria_avaliacao = parseInt(this.getUrlParameter("id_secretaria_avaliacao"))
            this.fastSecretariaAvaliacao.id_secretaria_avaliacao_uc = parseInt(this.getUrlParameter("id_secretaria_avaliacao_uc"))
            this.fastSecretariaAvaliacao.id_avaliacao_modelo = parseInt(this.getUrlParameter("id_avaliacao_modelo"))
            
            console.log("this.fastSecretariaAvaliacao", this.fastSecretariaAvaliacao)

            this.getQuestoesSecretariaAvaliacao();
          }
          this.getFastPlataformaProvas();
          this.getFastDisciplinas();
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getFastPlataformaProvas() {
      // Inicia loading
      this.$store.state.fastCarregando = true;
      this.provasTotal = [];
      this.provasFiltro = [];
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_prova/lista_nquestoes?id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        console.log("getFastPlataformaProvas", json);
        let obj = Array.from(json);
        if (obj.length > 0) {
          obj.forEach((e, index, objProva) => {
            e.questoes = [];
            e.pageOfQuestoes = [];
          })
          if (this.getUrlParameter("atividade")) {
            this.provasTotal = obj.filter(p => p.tipo_prova != "D")
            this.provasFiltro = this.provasTotal
          } else {
            this.provasTotal = obj;
            this.provasFiltro = obj;
          }
          this.msgCarregamentoProva = "";
        } else {
          this.msgCarregamentoProva = "Nenhuma prova cadastrada";
        }
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
      }
    },
    criaFastProva() {
      if (this.novaProva.nome_prova != "") {
        let fast_plataforma_prova = {
          nome_prova: this.novaProva.nome_prova,
          tipo_prova: this.novaProva.tipo_prova,
          id_plataforma: this.$route.params.id_plataforma,
        };
        fetch(`${settings.endApiFastEad}api/fast_plataforma_prova/insere`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_prova)))
          .then((resp) => resp.json())
          .then((obj) => {
            if (obj.length > 0) {
              this.getFastPlataformaProvas();
              this.hideModal("modalCriarProva");
            }
          })
          .catch((e) => console.log(e));
      } else {
        this.exibeToasty("O nome da prova não pode ficar em branco.", "error")
      }
    },
    exibeModalExcluirProva(id_prova) {
      this.showModal("modalExcluirProva");
      this.excluirProva.push({ id_prova: id_prova });
    },
    confirmaExcluirProva() {
      if (this.excluirProva.length > 0) {
        let fast_plataforma_prova = {
          id_prova: this.excluirProva[0].id_prova,
          id_plataforma: this.$route.params.id_plataforma,
        };
        this.excluirProva = [];
        fetch(`${settings.endApiFastEad}api/fast_plataforma_prova/exclui`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_prova)))
          .then((resp) => resp.json())
          .then((obj) => {
            this.exibeToasty("Prova excluída com sucesso", "success")
            this.getFastPlataformaProvas();
            this.hideModal("modalExcluirProva");
          })
          .catch((e) => console.log(e));
      }
    },
    confirmaAlteracaoProva(id_prova) {
      let prova = this.provasTotal.find((o) => o.id_prova === id_prova);

      let fast_plataforma_prova = {
        id_prova: prova.id_prova,
        nome_prova: prova.nome_prova,
        tipo_prova: prova.tipo_prova,
        ano: prova.ano,
        id_plataforma: prova.id_plataforma,
      };

      fetch(`${settings.endApiFastEad}api/fast_plataforma_prova/atualiza`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_prova)))
        .then((resp) => resp.json())
        .then((obj) => {
          this.getFastPlataformaProvas();
          // Notificação
          this.exibeToasty("Alterações realizadas com sucesso", "success")
        })
        .catch((e) => {
          console.log(e);
          // Notificação
          this.exibeToasty("Erro ao salvar alterações", "error")
        });
    },
    exibeModalCriarQuestao(id_prova) {
      this.novaQuestao.id_prova = id_prova;
      this.showModal("modalCadastrarQuestao");
    },
    exibeModalCriarQuestaoDissertativa(id_prova) {
      this.novaQuestao.id_prova = id_prova;
      this.showModal("modalCadastrarQuestaoDissertativa");
    },
    exibeModalBuscarQuestoesConcurseiro(id_prova) {
      this.novaQuestao.id_prova = id_prova;
      this.showModal("modalBuscarQuestoesConcurseiro");
    },
    resetaNovaQuestao() {
      this.novaQuestao = {
        id_prova: "",
        pergunta: "",
        tipo_questao: "O",
        alternativas: [
          {
            alternativa: "",
            correta: false,
          },
          {
            alternativa: "",
            correta: false,
          },
          {
            alternativa: "",
            correta: false,
          },
          {
            alternativa: "",
            correta: false,
          },
          {
            alternativa: "",
            correta: false,
          },
        ],
      };
    },
    criaFastQuestao() {
      let corretas = 0;
      this.novaQuestao.alternativas.forEach((a) => {
        if (a.correta) corretas++;
      });
      if (!corretas) {
        this.$toast.open({
          message: "A questão deve conter ao menos uma alternativa correta",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
      } else {
        if (this.novaQuestao.pergunta != "") {
          let fast_plataforma_questao = {
            id_prova: this.novaQuestao.id_prova,
            pergunta: this.novaQuestao.pergunta,
            id_plataforma: this.$route.params.id_plataforma,
          };
          fetch(`${settings.endApiFastEad}api/fast_plataforma_questao/insere`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_questao)))
            .then((resp) => resp.json())
            .then((obj) => {
              if (obj.length > 0) {
                this.exibeToasty("Questão cadastrada com sucesso", "success")

                let id_questao = obj[0].id_questao;
                if (id_questao) {
                  // Insere alternativas
                  this.novaQuestao.alternativas.forEach((a, index) => {
                    if (a.alternativa != "") {
                      let fast_plataforma_alternativa = {
                        id_questao: id_questao,
                        alternativa: a.alternativa,
                        correta: a.correta,
                        fast_plataforma_questao: {
                          id_plataforma: this.$route.params.id_plataforma,
                        },
                      };
                      fetch(`${settings.endApiFastEad}api/fast_plataforma_alternativa/insere`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_alternativa)))
                        .then((resp) => resp.json())
                        .then((obj) => {
                          if (obj.length > 0) {
                            this.exibeToasty("Alternativa cadastrada com sucesso", "success")
                          }
                        });
                    }
                    // Última repetição
                    if (index == this.novaQuestao.alternativas.length - 1) {
                      this.pageOfProvas.forEach((p) => {
                        if (p.id_prova == this.novaQuestao.id_prova) {
                          const e = { target: { value: p.nome_prova } };
                          this.filtraProva(e);
                          p.n_questoes++;
                          this.getFastPlataformaQuestoes(p);
                        }
                      });
                      this.hideModal("modalCadastrarQuestao");
                      this.resetaNovaQuestao();
                    }
                  });
                } else {
                  this.pageOfProvas.forEach((p) => {
                    if (p.id_prova == this.novaQuestao.id_prova) {
                      const e = { target: { value: p.nome_prova } };
                      this.filtraProva(e);
                      p.n_questoes++;
                      this.getFastPlataformaQuestoes(p);
                    }
                  });

                  this.hideModal("modalCadastrarQuestao");
                  this.resetaNovaQuestao();
                }
              }
            })
            .catch((e) => console.log(e));
        } else {
          this.exibeToasty("A questão não pode ficar em branco.", "error")
        }
      }
    },
    criaFastQuestaoDissertativa() {
      if (this.novaQuestao.pergunta != "") {
          let fast_plataforma_questao = {
            id_prova: this.novaQuestao.id_prova,
            pergunta: this.novaQuestao.pergunta,
            id_plataforma: this.$route.params.id_plataforma,
            tipo_questao: "D"
          };
          fetch(`${settings.endApiFastEad}api/fast_plataforma_questao/insere`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_questao)))
            .then((resp) => resp.json())
            .then((obj) => {
              if (obj.length > 0) {
                this.exibeToasty("Questão cadastrada com sucesso", "success")

                let id_questao = obj[0].id_questao;                
                this.pageOfProvas.forEach((p) => {
                  if (p.id_prova == this.novaQuestao.id_prova) {
                    const e = { target: { value: p.nome_prova } };
                    this.filtraProva(e);
                    p.n_questoes++;
                    this.getFastPlataformaQuestoes(p);
                  }
                });
                this.hideModal("modalCadastrarQuestaoDissertativa");
                this.resetaNovaQuestao();                
              }
            })
            .catch((e) => console.log(e));
        } else {
          this.exibeToasty("A questão não pode ficar em branco.", "error")
        }
    },
    async getFastPlataformaQuestoes(e) {
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_questao/lista?id_plataforma=" + this.$route.params.id_plataforma + "&id_prova=" + e.id_prova, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          //e.questoes = obj;
          const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_questao))).map((id_questao) => {
            return obj.find((a) => a.id_questao === id_questao);
          });

          questoesUnicas.forEach((a, index) => {
            obj.forEach((q, index2) => {
              if (a.id_questao == q.id_questao) {
                if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                questoesUnicas[index].alternativas.push(q);
              }
            });
          });
          e.questoes = questoesUnicas;
        } else {
          e.questoes = [];
        }

        e.questoes.forEach((q) => {
          if (q.alternativas) {
            let correta = 0;
            q.alternativas.forEach((a) => {
              if (a.correta) correta++;
            });
            if (!correta) {
              q.questao_sem_correta = true;
            }
            //console.log("Corretas", correta);
          }
        });

        this.$store.state.fastCarregando = false;
        //console.log("Questões", e);

        /*  this.provasTotal.forEach((p) => {
          if (p.id_prova == e.id_prova) p.questoes = p;
        });
        this.provasFiltro.forEach((p) => {
          if (p.id_prova == e.id_prova) p.questoes = p;
        });*/

        //console.log(this.provasTotal);
        //this.provasTotal.push(e);
        //this.provasFiltro.push(e);
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    tabProvasClicked(e) {
      console.log("Clicou", e);
    },
    removeHTMLpergunta(str, limite) {
      let strFormated = "";
      if (str) {
        strFormated = str.replace(/<[^>]+>/g, "");
        if (strFormated.length > 10) strFormated = strFormated.substring(0, limite) + "...";
      }
      if (!strFormated.length) strFormated = "Questão com caracteres em HTML";
      return strFormated;
    },
    exibeModalEditarQuestao(questao) {
      this.questaoEditar = questao;
      console.log(this.questaoEditar)
      this.showModal("modalEditarQuestao");
    },
    salvaAlteracoesQuestaoEditar() {
      let corretas = 0;
      this.questaoEditar.alternativas.forEach((a) => {
        if (a.correta) corretas++;
      });

      if (!corretas) {
        this.$toast.open({
          message: "A questão deve conter ao menos uma alternativa correta",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
      } else {
        let fast_plataforma_questao = {
          id_questao: this.questaoEditar.id_questao,
          pergunta: this.questaoEditar.pergunta,
          id_area_disciplina: this.questaoEditar.id_area_disciplina,
          id_plataforma_area_disciplina: this.questaoEditar.id_plataforma_area_disciplina,
          id_plataforma: this.$route.params.id_plataforma,
        };

        fetch(`${settings.endApiFastEad}api/fast_plataforma_questao/atualiza`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_questao)))
          .then((resp) => resp.json())
          .then((obj) => {
            // Notificação
            this.exibeToasty("Pergunta salva com sucesso", "success")
          })
          .catch((e) => {
            console.log(e);
            // Notificação
            this.exibeToasty("Erro ao salvar alterações", "error")
          });

        // Atualiza alternativas
        this.questaoEditar.alternativas.forEach((a, index, objEditar) => {
          if (a.id_alternativa != "") {
            let fast_plataforma_alternativa = {
              id_alternativa: a.id_alternativa,
              id_questao: this.questaoEditar.id_questao,
              alternativa: a.alternativa,
              correta: a.correta,
              fast_plataforma_questao: {
                id_plataforma: this.$route.params.id_plataforma,
              },
            };
            // Se alternativa estiver em branco então exclui
            if (a.alternativa == "") {
              fetch(`${settings.endApiFastEad}api/fast_plataforma_alternativa/exclui`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_alternativa)))
                .then((resp) => resp.json())
                .then((obj) => {
                  this.exibeToasty("Alternativa excluída com sucesso", "success")
                  // Se for a última fecha modal e zera objeto
                  if (index == objEditar.length - 1) {
                    this.hideModal("modalEditarQuestao");
                    this.questaoEditar = {};
                  }
                });
            } else {
              // Se não possuir id_alternativa insere nova
              if (a.id_alternativa == "0") {
                fetch(`${settings.endApiFastEad}api/fast_plataforma_alternativa/insere`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_alternativa)))
                  .then((resp) => resp.json())
                  .then((obj) => {
                    this.exibeToasty("Alternativa adicionada com sucesso", "success")
                    // Se for a última fecha modal e zera objeto
                    if (index == objEditar.length - 1) {
                      this.hideModal("modalEditarQuestao");
                      this.questaoEditar = {};
                    }
                  });
              } else {
                fetch(`${settings.endApiFastEad}api/fast_plataforma_alternativa/atualiza`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_alternativa)))
                  .then((resp) => resp.json())
                  .then((obj) => {
                    this.exibeToasty("Alternativa atualizada com sucesso", "success")
                    // Se for a última fecha modal e zera objeto
                    if (index == objEditar.length - 1) {
                      this.hideModal("modalEditarQuestao");
                      this.questaoEditar = {};
                    }
                  });
              }
            }
          }
        });
      }
    },
    salvaAlteracoesQuestaoDissertativaEditar() {
       
        let fast_plataforma_questao = {
          id_questao: this.questaoEditar.id_questao,
          pergunta: this.questaoEditar.pergunta,
          id_area_disciplina: this.questaoEditar.id_area_disciplina,
          id_plataforma_area_disciplina: this.questaoEditar.id_plataforma_area_disciplina,
          id_plataforma: this.$route.params.id_plataforma,
        };
        fetch(`${settings.endApiFastEad}api/fast_plataforma_questao/atualiza`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_questao)))
          .then((resp) => resp.json())
          .then((obj) => {
            // Notificação
            this.exibeToasty("Pergunta salva com sucesso", "success")
          })
          .catch((e) => {
            console.log(e);
            // Notificação
            this.exibeToasty("Erro ao salvar alterações", "error")
          });
    },
    filtraProva(e) {
      let text = e.target.value;
      this.provasFiltro = this.provasTotal.filter((e) => e.nome_prova.toLowerCase().indexOf(text.toLowerCase()) != "-1");
    },
    adicionaAlternativaEdicao() {
      this.questaoEditar.alternativas.push({
        id_alternativa: "0",
        alternativa: "",
        correta: "",
      });
    },
    exibeModalExcluirQuestao(questao) {
      this.showModal("modalExcluirQuestao");
      this.excluirQuestao.push(questao);
    },
    confirmaExcluirQuestao() {
      if (this.excluirQuestao.length > 0) {
        let fast_plataforma_questao = {
          id_questao: this.excluirQuestao[0].id_questao,
          id_prova: this.excluirQuestao[0].id_prova,
          id_plataforma: this.$route.params.id_plataforma,
        };
        this.excluirQuestao = [];
        fetch(`${settings.endApiFastEad}api/fast_plataforma_questao/exclui`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_questao)))
          .then((resp) => resp.json())
          .then((obj) => {
            this.exibeToasty("Prova excluída com sucesso", "success")
            this.getFastPlataformaProvas();
            this.hideModal("modalExcluirQuestao");
          })
          .catch((e) => console.log(e));
      }
    },
    // Atividade QUIZ
    async getQuestoesAtividadeQuiz() {
      this.fastAtividade.questoes = [];
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_quiz/lista?id_atividade=" + this.fastAtividade.id_atividade, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          //e.questoes = obj;
          const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_questao))).map((id_questao) => {
            return obj.find((a) => a.id_questao === id_questao);
          });

          questoesUnicas.forEach((a, index) => {
            obj.forEach((q, index2) => {
              if (a.id_questao == q.id_questao) {
                if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                questoesUnicas[index].alternativas.push(q);
              }
            });
          });
          this.fastAtividade.questoes = questoesUnicas;
        } else {
          this.fastAtividade.questoes = [];
        }

        // Para loading
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Atividade Avaliação
    async getQuestoesAtividadeAvaliacao() {
      this.fastAtividade.questoes = [];
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_prova/lista_novo?id_atividade=" + this.fastAtividade.id_atividade, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          //e.questoes = obj;
          const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_questao))).map((id_questao) => {
            return obj.find((a) => a.id_questao === id_questao);
          });

          questoesUnicas.forEach((a, index) => {
            obj.forEach((q, index2) => {
              if (a.id_questao == q.id_questao) {
                if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                questoesUnicas[index].alternativas.push(q);
              }
            });
          });
          this.fastAtividade.questoes = questoesUnicas;
        } else {
          this.fastAtividade.questoes = [];
        }

        // Para loading
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Secretaria Avaliação
    async getQuestoesSecretariaAvaliacao() {
      this.fastSecretariaAvaliacao.questoes = [];
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_avaliacao_questoes/lista?id_secretaria_avaliacao=" + this.fastSecretariaAvaliacao.id_secretaria_avaliacao, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          //e.questoes = obj;
          const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_questao))).map((id_questao) => {
            return obj.find((a) => a.id_questao === id_questao);
          });

          questoesUnicas.forEach((a, index) => {
            obj.forEach((q, index2) => {
              if (a.id_questao == q.id_questao) {
                if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                questoesUnicas[index].alternativas.push(q);
              }
            });
          });
          this.fastSecretariaAvaliacao.questoes = questoesUnicas;
        } else {
          this.fastSecretariaAvaliacao.questoes = [];
        }

        // Para loading
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    adicionarQuestaoAtividade(id_questao, id_prova, tipo) {
      console.log("tipo", tipo)
      // Inicia loading
      this.$store.state.fastCarregando = true;
      if (tipo == "quiz") this.adicionarQuestaoQuiz(id_questao, id_prova);
      if (tipo == "avaliacao") this.adicionarQuestaoAvaliacao(id_questao, id_prova);
      if (tipo == "secretaria_avaliacao") this.adicionarQuestaoSecretariaAvaliacao(id_questao, id_prova);
    },
    async adicionarQuestaoQuiz(id_questao, id_prova) {
      let model = {
        fast_curso_aula_atividade_quiz: {
          id_questao: id_questao,
          id_atividade: this.fastAtividade.id_atividade,
        },
        fast_plataforma_prova: {
          id_prova: id_prova,
        },
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_quiz/insere", this.fastAjaxOptions("POST", JSON.stringify(model)));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          this.fastAtividade.questoes = obj;
        }
        this.exibeToasty("Questão adicionada com sucesso", "success")
        this.getQuestoesAtividadeQuiz();
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async adicionarQuestaoAvaliacao(id_questao, id_prova) {
      let model = {
        fast_curso_aula_atividade_prova: {
          id_questao: id_questao,
          id_atividade: this.fastAtividade.id_atividade,
        },
        fast_plataforma_prova: {
          id_prova: id_prova,
          id_plataforma: this.$store.state.fastPlataforma.id_plataforma
        },
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_prova/insere_novo", this.fastAjaxOptions("POST", JSON.stringify(model)));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          this.fastAtividade.questoes = obj;
        }
        this.exibeToasty("Questão adicionada com sucesso", "success")
        this.getQuestoesAtividadeAvaliacao();
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async adicionarQuestaoSecretariaAvaliacao(id_questao, id_prova) {
      if (id_prova) {
        let obj = {
          fast_secretaria_avaliacao_questoes: {
            id_secretaria_avaliacao: this.fastSecretariaAvaliacao.id_secretaria_avaliacao,
          },
          fast_plataforma_prova: {
            id_prova: id_prova
          }
        }
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_avaliacao_questoes/insere_prova", this.fastAjaxOptions("POST", JSON.stringify(obj)));
          let json = await resp.json();
          let res = Array.from(json);
          if (res.length > 0) {
            this.exibeToasty("Prova adicionada com sucesso", "success")
            this.getQuestoesSecretariaAvaliacao();
          }        
          this.$store.state.fastCarregando = false
        } catch (e) {
          console.log("Erro", e);
          this.$store.state.fastCarregando = false
          this.exibeToasty("Erro ao inserir questões", "error")
        }
      } else {
        let fast_secretaria_avaliacao_questoes = {
          id_secretaria_avaliacao: this.fastSecretariaAvaliacao.id_secretaria_avaliacao,
          id_questao: id_questao,
          questao_peso: 0,
          sequencia: 0
        }
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_avaliacao_questoes/insere", this.fastAjaxOptions("POST", JSON.stringify(fast_secretaria_avaliacao_questoes)));
          let json = await resp.json();
          let res = Array.from(json);
          if (res.length > 0) {
            this.exibeToasty("Questão adicionada com sucesso", "success")
            this.getQuestoesSecretariaAvaliacao();
          }        
          this.$store.state.fastCarregando = false
        } catch (e) {
          console.log("Erro", e);
          this.$store.state.fastCarregando = false
          this.exibeToasty("Erro ao inserir questões", "error")
        }
      }
      
    },
    verificaQuestaoNoQuiz(id_questao) {
      let encontrado = this.fastAtividade.questoes.filter((e) => e.id_questao == id_questao);
      return encontrado.length;
    },
    verificaQuestaoSecretariaAvaliacao(id_questao){
      let encontrado = this.fastSecretariaAvaliacao.questoes.filter((e) => e.id_questao == id_questao);
      return encontrado.length;
    },
    verificaQuestoesQuizNaProva(questoes) {
      let totalEncontrado = [];

      questoes.forEach((e) => {
        this.fastAtividade.questoes.forEach((p) => {
          if (p.id_questao == e.id_questao) totalEncontrado.push(p);
        });
      });
      if (questoes.length == totalEncontrado.length) return false;

      return true;
    },
    verificaQuestoesSecretariaAvaliacaoNaProva(questoes) {
      let totalEncontrado = [];

      questoes.forEach((e) => {
       this.fastSecretariaAvaliacao.questoes.forEach((p) => {
          if (p.id_questao == e.id_questao) totalEncontrado.push(p);
        });
      });
      console.log("questoes.length", questoes.length)
      console.log("totalEncontrado.length", totalEncontrado.length)
      if (questoes.length == totalEncontrado.length) return false;

      return true;
    },
    removerQuestaoAtividade(id_questao, tipo) {
      // Inicia loading
      this.$store.state.fastCarregando = true;
      if (tipo == "quiz") this.removerQuestaoQuiz(id_questao);
      if (tipo == "avaliacao") this.removerQuestaoAvaliacao(id_questao);
      if (tipo == "secretaria_avaliacao") this.removerQuestaoSecretariaAvaliacao(id_questao);
    },
    async removerQuestaoQuiz(id_questao) {
      let model = {
        fast_curso_aula_atividade_quiz: {
          id_questao: id_questao,
          id_atividade: this.fastAtividade.id_atividade,
        },
        fast_plataforma_prova: {
          id_prova: 0,
        },
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_quiz/exclui", this.fastAjaxOptions("POST", JSON.stringify(model)));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          this.fastAtividade.questoes = obj;
        }
        this.exibeToasty("Questão excluída com sucesso", "success")
        this.getQuestoesAtividadeQuiz();
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async removerQuestaoAvaliacao(id_questao) {
      let fast_curso_aula_atividade_prova = {
        id_questao: id_questao,
        id_atividade: this.fastAtividade.id_atividade,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade_prova/exclui_novo", this.fastAjaxOptions("POST", JSON.stringify(fast_curso_aula_atividade_prova)));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          this.fastAtividade.questoes = obj;
        }
        this.exibeToasty("Questão excluída com sucesso", "success")
        this.getQuestoesAtividadeAvaliacao();
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async removerQuestaoSecretariaAvaliacao(id_questao) {
      let obj = {
        id_questao: id_questao,
        id_secretaria_avaliacao: this.fastSecretariaAvaliacao.id_secretaria_avaliacao
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_avaliacao_questoes/exclui", this.fastAjaxOptions("POST", JSON.stringify(obj)));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          this.exibeToasty("Questão excluída com sucesso", "success")
          this.getQuestoesSecretariaAvaliacao();
        }
        
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Disciplinas
    async getFastDisciplinas() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_disciplinas/lista?id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          obj.forEach((e) => {
            e.name = e.descricao;
            e.id = e.id_plataforma_area_disciplina;
            e.isLeaf = false;
            e.addLeafNodeDisabled = true;
          });
          let arrayDisciplinasPai = obj.filter((d) => {
            if (d.id_plataforma_area_disciplina_pai == "0") return d;
          });
          arrayDisciplinasPai.forEach(function(d, index) {
            verificaNivel2(d, index);
          });

          function verificaNivel2(d, index) {
            obj.forEach(function(d2) {
              if (d.id_plataforma_area_disciplina == d2.id_plataforma_area_disciplina_pai) {
                if (!arrayDisciplinasPai[index].children) arrayDisciplinasPai[index].children = [];
                arrayDisciplinasPai[index].children.push(d2);

                verificaNivelUltimos(d2);
              }
            });
          }

          function verificaNivelUltimos(d) {
            obj.forEach(function(d2) {
              if (d.id_plataforma_area_disciplina == d2.id_plataforma_area_disciplina_pai) {
                if (!d.children) d.children = [];
                d.children.push(d2);
                verificaNivelUltimos(d2);
              }
            });
          }

          this.treeDisciplinas = new Tree(arrayDisciplinasPai);
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalEscolherDisciplina(questao) {
      this.showModal("modalEscolherDisciplina");
      this.questaoEditar = questao;
    },
    confirmaDisciplinaEscolhida(obj) {
      this.questaoEditar.id_plataforma_area_disciplina = obj.id_plataforma_area_disciplina;
      let fast_plataforma_questao = {
        id_questao: this.questaoEditar.id_questao,
        pergunta: this.questaoEditar.pergunta,
        id_area_disciplina: this.questaoEditar.id_area_disciplina,
        id_plataforma_area_disciplina: this.questaoEditar.id_plataforma_area_disciplina,
        id_plataforma: this.$route.params.id_plataforma,
      };

      this.pageOfProvas[0].questoes.forEach((e) => {
        if (e.id_questao == this.questaoEditar.id_questao) {
          e.disciplina = obj.name;
          e.id_plataforma_area_disciplina = obj.id;
        }
      });

      fetch(`${settings.endApiFastEad}api/fast_plataforma_questao/atualiza`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_questao)))
        .then((resp) => resp.json())
        .then((obj) => {
          this.hideModal("modalEscolherDisciplina");
          // Notificação
          this.exibeToasty("Disciplina salva com sucesso", "success")
        })
        .catch((e) => {
          // Notificação
          this.exibeToasty("Erro ao salvar disciplina", "error")
        });
    },
    // Atividade
    async voltarParaAtividade(id_atividade) {
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade?id_atividade=" + id_atividade + "&id_curso=&id_aula=", this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          window.location.href = "/plataforma/" + this.$route.params.id_plataforma + "/admin/curso/" + obj[0].id_curso + "/aulas/" + obj[0].id_aula + "/atividade/" + obj[0].id_atividade + "";
        } else {
          this.$toast.open({
            message: "Erro ao identificar atividade",
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "bottom-right",
          });
        }
      } catch (e) {
        console.log("Erro", e);
        this.$toast.open({
          message: "Erro ao identificar atividade",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
      }
    },
    voltarParaSecretariaAvaliacao(){
      window.location.href = "/plataforma/" + this.$route.params.id_plataforma + "/secretaria/curso/" + this.getUrlParameter("id_secretaria_curso") + "/programa/" + this.getUrlParameter("id_secretaria_curso_programa") + "?id_secretaria_avaliacao_uc=" + this.fastSecretariaAvaliacao.id_secretaria_avaliacao_uc + '&tipo=' + this.getUrlParameter("tipo");
    },
    // Questões do concurseiro
    async getConcurseiroQuestoes(pagina) {
      this.$store.state.fastCarregando = true;

      if (pagina) this.concurseiroQuestoesFiltro.pagina = pagina;
      //console.log("Buscando questões na página..." + pagina);

      this.concurseiroQuestoes = [];
      this.concurseiroQuestoesFiltro.total = 0;
      try {
        let resp = await fetch(
          settings.endApiConcurseiro +
            "api/concurseiro/questoes/filtro_total?pergunta=" +
            this.concurseiroQuestoesFiltro.pergunta +
            "&id_questao=" +
            this.concurseiroQuestoesFiltro.id_questao +
            "&id_area_disciplina=" +
            this.concurseiroQuestoesFiltro.id_area_disciplina +
            "&ano=" +
            this.concurseiroQuestoesFiltro.ano +
            "&id_banca=" +
            this.concurseiroQuestoesFiltro.id_banca +
            "&id_cargo=" +
            this.concurseiroQuestoesFiltro.id_cargo +
            "&nivel=" +
            this.concurseiroQuestoesFiltro.nivel +
            "&id_referencia=" +
            this.concurseiroQuestoesFiltro.id_referencia,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          this.concurseiroQuestoesFiltro.total = obj[0].total_questoes;

          console.log("Total de questões", this.concurseiroQuestoesFiltro.total)

          this.concurseiroQuestoesFiltro.totalPaginas = Math.ceil(this.concurseiroQuestoesFiltro.total / this.concurseiroQuestoesFiltro.nQuestoes);

          console.log("Página", this.concurseiroQuestoesFiltro.pagina)
          console.log("nQuestoes", this.concurseiroQuestoesFiltro.nQuestoes)
          console.log("totalPaginas", this.concurseiroQuestoesFiltro.totalPaginas)

          try {
            let resp = await fetch(
              settings.endApiConcurseiro +
                "api/concurseiro/questoes/filtro?pergunta=" +
                this.concurseiroQuestoesFiltro.pergunta +
                "&id_questao=" +
                this.concurseiroQuestoesFiltro.id_questao +
                "&id_area_disciplina=" +
                this.concurseiroQuestoesFiltro.id_area_disciplina +
                "&ano=" +
                this.concurseiroQuestoesFiltro.ano +
                "&id_banca=" +
                this.concurseiroQuestoesFiltro.id_banca +
                "&id_cargo=" +
                this.concurseiroQuestoesFiltro.id_cargo +
                "&nivel=" +
                this.concurseiroQuestoesFiltro.nivel +
                "&id_referencia=" +
                this.concurseiroQuestoesFiltro.id_referencia +
                "&pagina=" +
                this.concurseiroQuestoesFiltro.pagina +
                "&nQuestoes=" +
                this.concurseiroQuestoesFiltro.nQuestoes,
              this.fastAjaxOptions("GET")
            );
            let json = await resp.json();
            let obj = Array.from(json);
            if (obj.length > 0) {
              this.concurseiroQuestoes = obj;
              console.log("this.concurseiroQuestoes", this.concurseiroQuestoes);
              console.log("this.concurseiroQuestoesFiltro", this.concurseiroQuestoesFiltro);
            }
            this.$store.state.fastCarregando = false;
          } catch (e) {
            console.log("Erro", e);
            this.$store.state.fastCarregando = false;
          }
        }
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
      }
    },
    classePaginacao(index) {
      if (this.concurseiroQuestoesFiltro.pagina == index) return "active";
      else return "";
    },
    async adicionaQuestaoConcurseiro(questao) {
      this.$store.state.fastCarregando = true;
      this.buscaPerguntaConcurseiro(questao.id_questao).then((r) => {
        this.novaQuestao.pergunta = r[0].PERGUNTA;

        this.buscaAlternativasConcurseiro(questao.id_questao).then((a) => {
          a.forEach((e, index) => {
            this.novaQuestao.alternativas[index].alternativa = e.ALTERNATIVA;
            this.novaQuestao.alternativas[index].correta = e.CORRETA;
          });
          //console.log("this.novaQuestao", this.novaQuestao);
          if (this.novaQuestao.pergunta != "") {
            let fast_plataforma_questao = {
              id_prova: this.novaQuestao.id_prova,
              pergunta: this.novaQuestao.pergunta,
              id_plataforma: this.$route.params.id_plataforma,
            };
            fetch(`${settings.endApiFastEad}api/fast_plataforma_questao/insere`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_questao)))
              .then((resp) => resp.json())
              .then((obj) => {
                if (obj.length > 0) {
                  let id_questao = obj[0].id_questao;
                  if (id_questao) {
                    this.exibeToasty("Questão cadastrada com sucesso", "success")
                    // Insere alternativas
                    this.novaQuestao.alternativas.forEach((a, index) => {
                      if (a.alternativa != "") {
                        let fast_plataforma_alternativa = {
                          id_questao: id_questao,
                          alternativa: a.alternativa,
                          correta: a.correta,
                          fast_plataforma_questao: {
                            id_plataforma: this.$route.params.id_plataforma,
                          },
                        };
                        fetch(`${settings.endApiFastEad}api/fast_plataforma_alternativa/insere`, this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_alternativa)))
                          .then((resp) => resp.json())
                          .then((obj) => {
                            if (obj.length > 0) {
                              this.exibeToasty("Alternativa cadastrada com sucesso", "success")
                            }
                          });
                      }
                      // Última repetição
                      if (index == this.novaQuestao.alternativas.length - 1) {
                        this.pageOfProvas.forEach((p) => {
                          if (p.id_prova == this.novaQuestao.id_prova) {
                            const e = { target: { value: p.nome_prova } };
                            this.filtraProva(e);
                            p.n_questoes++;
                            this.getFastPlataformaQuestoes(p);
                          }
                        });
                        this.hideModal("modalBuscarQuestoesConcurseiro");
                        this.resetaNovaQuestao();
                        this.$store.state.fastCarregando = false;
                      }
                    });
                  } else {
                    this.exibeToasty("Erro ao cadastrar questão", "error")
                    this.getFastPlataformaProvas();
                    this.hideModal("modalBuscarQuestoesConcurseiro");
                    this.resetaNovaQuestao();
                    this.$store.state.fastCarregando = false;
                  }
                }
              })
              .catch((e) => {
                console.log(e);
                this.$store.state.fastCarregando = false;
              });
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("A questão não pode ficar em branco.", "error")
          }
        });
      });
    },
    async buscaPerguntaConcurseiro(id_questao) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiConcurseiro + "api/concurseiro/questoes/listaquestoeslivre?id_questao=" + id_questao, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    async buscaAlternativasConcurseiro(id_questao) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiConcurseiro + "api/concurseiro/alternativas/questao?id=" + id_questao, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    setImageQuestaoEdit(e) {
      this.hideModal("modalAdicionarImagemQuestaoEdit")
      this.exibeToasty("Carregando imagem...", "info");
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        
        this.exibeToasty("Selecione um arquivo de imagem", "error")
        return;
      }

      if (file.size / 1024 > 5120) {
        this.exibeToasty("O limite de tamanho do arquivo é 5MB", "error")
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          // Captura imagem em base64
          let imageBase64 = event.target.result
          // Monta objeto
          const obj = {
            id_plataforma: this.$route.params.id_plataforma,
            tipo: "mural", // tipos: email, mural, questao
            imageBase64: imageBase64
          }
          // Envia imagem em base64 para server 
          this.promisePostFastApi(obj, "api/fast_plataforma/upload_image").then(res => {
            const retorno = JSON.parse(res)
            console.log(retorno)
            if (retorno.error) {
              this.exibeToasty(e.description, "error")
            } else { 
              if (retorno.url_image) {   
                // Captura link da imagem e insere no html do email
                          
                  this.questaoEditar.pergunta += `<img src="${retorno.url_image}" style="max-width: 100%">`
                  this.exibeToasty("Imagem inserida com sucesso", "success");
                
                
              }
            }
          }).catch(e => {         
            this.exibeToasty("Erro realizar upload da imagem", "error")
          })

          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        
        this.exibeToasty("Recurso não suportado pelo dispositivo", "error")
      }
    },
    setImageQuestaoNovo(e) {
      this.hideModal("modalAdicionarImagemQuestaoNovo")
      this.exibeToasty("Carregando imagem...", "info");
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        
        this.exibeToasty("Selecione um arquivo de imagem", "error")
        return;
      }

      if (file.size / 1024 > 5120) {
        this.exibeToasty("O limite de tamanho do arquivo é 5MB", "error")
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          // Captura imagem em base64
          let imageBase64 = event.target.result
          // Monta objeto
          const obj = {
            id_plataforma: this.$route.params.id_plataforma,
            tipo: "mural", // tipos: email, mural, questao
            imageBase64: imageBase64
          }
          // Envia imagem em base64 para server 
          this.promisePostFastApi(obj, "api/fast_plataforma/upload_image").then(res => {
            const retorno = JSON.parse(res)
            console.log(retorno)
            if (retorno.error) {
              this.exibeToasty(e.description, "error")
            } else { 
              if (retorno.url_image) {   
                // Captura link da imagem e insere no html do email
                          
                  this.novaQuestao.pergunta += `<img src="${retorno.url_image}" style="max-width: 100%">`
                  this.exibeToasty("Imagem inserida com sucesso", "success");
                
                
              }
            }
          }).catch(e => {         
            this.exibeToasty("Erro realizar upload da imagem", "error")
          })

          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        
        this.exibeToasty("Recurso não suportado pelo dispositivo", "error")
      }
    },
    setImageAlternativaEdit(e) {
      this.hideModal("modalAdicionarImagemAlternativaEdit")
      this.exibeToasty("Carregando imagem...", "info");
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        
        this.exibeToasty("Selecione um arquivo de imagem", "error")
        return;
      }

      if (file.size / 1024 > 5120) {
        
        this.exibeToasty("O limite de tamanho do arquivo é 5MB", "error")
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          // Captura imagem em base64
          let imageBase64 = event.target.result
          // Monta objeto
          const obj = {
            id_plataforma: this.$route.params.id_plataforma,
            tipo: "mural", // tipos: email, mural, questao
            imageBase64: imageBase64
          }
          // Envia imagem em base64 para server 
          this.promisePostFastApi(obj, "api/fast_plataforma/upload_image").then(res => {
            const retorno = JSON.parse(res)
            console.log(retorno)
            if (retorno.error) {
              this.exibeToasty(e.description, "error")
            } else { 
              if (retorno.url_image) {   
                // Captura link da imagem e insere no html do email
                        
                    this.questaoEditarImagem.alternativa += `<img src="${retorno.url_image}" style="max-width: 100%">`
                    this.exibeToasty("Imagem inserida com sucesso", "success");
                  
                
              }
            }
          }).catch(e => {         
            this.exibeToasty("Erro realizar upload da imagem", "error")
          })

          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        
        this.exibeToasty("Recurso não suportado pelo dispositivo", "error")
      }
    },
    setImageAlternativaNovo(e) {
      this.hideModal("modalAdicionarImagemAlternativaNovo")
      this.exibeToasty("Carregando imagem...", "info");
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        
        this.exibeToasty("Selecione um arquivo de imagem", "error")
        return;
      }

      if (file.size / 1024 > 5120) {
        
        this.exibeToasty("O limite de tamanho do arquivo é 5MB", "error")
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          // Captura imagem em base64
          let imageBase64 = event.target.result
          // Monta objeto
          const obj = {
            id_plataforma: this.$route.params.id_plataforma,
            tipo: "mural", // tipos: email, mural, questao
            imageBase64: imageBase64
          }
          // Envia imagem em base64 para server 
          this.promisePostFastApi(obj, "api/fast_plataforma/upload_image").then(res => {
            const retorno = JSON.parse(res)
            console.log(retorno)
            if (retorno.error) {
              this.exibeToasty(e.description, "error")
            } else { 
              if (retorno.url_image) {   
                // Captura link da imagem e insere no html do email
                        
                    this.novaQuestao.alternativas[this.indiceAlternativaAtual].alternativa += `<img src="${retorno.url_image}" style="max-width: 100%">`
                    this.exibeToasty("Imagem inserida com sucesso", "success");
                  
                
              }
            }
          }).catch(e => {         
            this.exibeToasty("Erro realizar upload da imagem", "error")
          })

          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        
        this.exibeToasty("Recurso não suportado pelo dispositivo", "error")
      }
    },
    exibeModalAdicionarImagemQuestaoEdit(questaoEditar) {
      this.questaoEditar = questaoEditar;
      this.showModal("modalAdicionarImagemQuestaoEdit");
    },
    exibeModalAdicionarImagemQuestaoNovo(novaQuestao) {
      this.novaQuestao = novaQuestao;
      this.showModal("modalAdicionarImagemQuestaoNovo");
    },
    exibeModalAdicionarImagemAlternativa(alternativa) {
      this.questaoEditarImagem = alternativa;
      this.showModal("modalAdicionarImagemAlternativaEdit");
    },
    exibeModalAdicionarImagemAlternativaNovo(indice) {
      this.indiceAlternativaAtual = indice
      console.log("oi", this.novaQuestao.alternativas[indice])
      this.showModal("modalAdicionarImagemAlternativaNovo");
    },
    
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

/* Tabs */
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 1em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}

/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}
</style>